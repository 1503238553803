<template>
  <div class="c-sheetpreview">
    <router-link class="c-sheetpreview__link" :to="'/sheets/' + sheet.quickstart" target="_blank">
      <img class="thb" :src="previewImage" alt="sheet.name">
      <h2>{{ sheet.name }}</h2>
      <p>{{ description }}</p>
      <div class="categories">
        <ul>
          <li v-for="cat in sheet.categories" :key="cat.id">{{ cat.title }}</li>
        </ul>
      </div>
    </router-link>
    <a href="#" class="c-sheetpreview__sharelink" title="Übung teilen" @click.prevent="$store.commit('showShareModal', sheet)"><span class="hidden">Share</span></a>
  </div>
</template>

<script>
export default {
  name: 'c-sheetpreview',
  props: ['sheet'],
  data () {
    return {

    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    previewImage() {
      if(this.sheet.hero_medium_url != '#') return this.sheet.hero_medium_url;
      return '/images/preview-dummy.png';
    },
    description() {
      let s = this.sheet.description.substr(0,100);
      s = s.replaceAll(/<[^>]*>?/gm, '');
      s = s.replaceAll('**', '');
      if(this.sheet.description.length > 100) s += '...';
      return s;
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .c-sheetpreview {
    position: relative;

    &__link {
      display: block;
      text-decoration: none;
      border: 1px solid #eee;
      border-radius: 4px;
      box-shadow: 0 4px 20px -4px rgba(#000, .05);
      padding: 0;
      box-sizing: border-box;
      padding-bottom: 1rem;
      overflow: hidden;

      &:hover {
        box-shadow: 0 2px 10px -4px rgba(#000, .1);
        border-color: #ccc;
        
        h2 {
          text-decoration: underline;
        }
      }
    }
    &__meta {
      position: absolute;
      top: 1px;
      background: rgba(#fff, .5);
      left: 0;
      width: 2rem;
      height: 2rem;
      border-top: 1px solid #eee;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__sharelink {
      position: absolute;
      top: .25rem;
      right: .25rem;
      background-color: rgba(#fff, 1);
      border-radius: 4px;
      border: 1px solid #ddd;
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url('../assets/icon-share.svg');
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      opacity: .7;

      &:hover {
        opacity: 1;
      }
    }

    img.thb {
      display: block;
      margin-bottom: .5rem;
      width: 100%;
      height: 8rem;
      object-fit: cover;
    }
    h2 {
      font-size: .8rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: .25rem;
      padding: 0 1rem;
    }
    p {
      font-size: .8rem;
      padding: 0 1rem;
    }
  }

  .categories {
    padding: .5rem 1rem 0 1rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: .65rem;
        letter-spacing: .02rem;
        // text-transform: uppercase;
        display: inline-block;
        padding: 0 .5rem;
        line-height: 2;
        background-color: #eee;
        margin-right: .25rem;
        // border: 1px solid #ccc;
        border-radius: 2px;
      }
    }
  }

</style>
